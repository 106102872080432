import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AngularFireAuth} from "@angular/fire/auth";
import {switchMap, take} from "rxjs/operators";

@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {
  constructor(private auth: AngularFireAuth) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('requesting: ', req.url);
    if (req.url.indexOf('/api/') !== 0) {
      return next.handle(req);
    }

    return this.auth.idToken.pipe(take(1))
      .pipe(switchMap((idToken) => {
        if (!idToken) {
          return throwError(new Error('Not authenticated, cannot run Requests on API'));
        }

        if (!req.headers.has('Authorization')) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${idToken}`
            }
          });
        }

        return next.handle(req);
      }));
  }
}
