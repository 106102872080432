<header class="line-below-header">
  <h1>Hilfe</h1>
</header>
<div class="main card ps">
  <img class="help-image" src="/assets/help-image.png">
  <b><p>Fragen, Anliegen, Beschwerden?</p>
    <p>Wir helfen gerne!<br/>
      Schreiben Sie uns eine Nachricht.</p>
  </b>
  <div class="mst">Sie erreichen uns jederzeit unter</div>
  <a href="mailto://hallo@collla.com" mat-button color="primary">hallo@collla.com</a>
</div>
