import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-page-privacy-policy',
  templateUrl: './page-privacy-policy.component.html',
  styleUrls: ['./page-privacy-policy.component.scss']
})
export class PagePrivacyPolicyComponent implements OnInit, OnDestroy {
  public html?: string;
  private sub?: Subscription;

  constructor(private fs: AngularFirestore) {
  }

  ngOnInit(): void {
    this.sub = this.fs.collection('info').doc<{ html: string }>('privacy-policy').get().subscribe(snap => {
      if (!snap.exists) {
        return;
      }
      this.html = snap.data()!.html;
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

}
