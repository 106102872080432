import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MediaObserver} from "@angular/flex-layout";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-frame-in',
  templateUrl: './frame-in.component.html',
  styleUrls: ['./frame-in.component.scss']
})
export class FrameInComponent implements OnInit, OnDestroy {
  public navOpen!: boolean;
  public isMobile!: boolean;


  private routerSub?: Subscription;
  private mediaSub?: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, private media: MediaObserver) {

  }

  ngOnDestroy(): void {
    this.mediaSub?.unsubscribe();
    this.routerSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isMobile = this.isMobile = this.media.isActive('lt-md');
    this.navOpen = !this.isMobile;
    this.routerSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        if (this.isMobile && this.navOpen) {
          this.navOpen = false;
        }
      });

    this.mediaSub = this.media.asObservable().subscribe((val) => {
      const wasMobile = this.isMobile;
      this.isMobile = this.media.isActive('lt-md');
      if (!this.isMobile) {
        this.navOpen = true;
      }

      if (!wasMobile && this.isMobile) {
        this.navOpen = false;
      }
    });
  }
}
