export const environment = {
  production: true,
  stage: "PRODUCTION-STAGE",
  cdn: {
    host: 'collla-wiki.web.app'
  },
  firebase: {
    apiKey: "AIzaSyAaBmfTyJSBfD2TBqTzzi7ZQWL21lEZ32E",
    authDomain: "collla.firebaseapp.com",
    databaseURL: "https://collla.firebaseio.com",
    projectId: "collla",
    storageBucket: "collla.appspot.com",
    messagingSenderId: "399743552772",
    appId: "1:399743552772:web:3a91df90efb7771005d451",
    measurementId: "G-09TQJQWGKD"
  },
  bugsnag: {
    apiKey: '6c565a8ef609345450ec4d4c850c286c',
    releaseStage: 'production',
  }
};
