import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatListModule} from "@angular/material/list";
import {PageHomeComponent} from './page-home/page-home.component';
import {FrameInComponent} from './frame-in/frame-in.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule} from "@angular/forms";
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {CardComponent} from './card/card.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MatFormFieldDefaultOptions} from "@angular/material/form-field/form-field";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatMenuModule} from "@angular/material/menu";
import {AngularFireModule} from "@angular/fire";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {AngularFireAnalyticsModule, ScreenTrackingService} from "@angular/fire/analytics";
import {AngularFireStorageModule} from "@angular/fire/storage";
import {AngularFireFunctionsModule, REGION} from "@angular/fire/functions";
import {MatTableModule} from "@angular/material/table";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ApiAuthInterceptor} from "./api-auth.interceptor";
import {PagePrivacyPolicyComponent} from './page-privacy-policy/page-privacy-policy.component';
import {PageImprintComponent} from './page-imprint/page-imprint.component';
import {PageTermsAndConditionsComponent} from './page-terms-and-conditions/page-terms-and-conditions.component';
import {environment} from "../environments/environment";
import {AppDatePipe} from './app-date.pipe';
import {ConfirmDialogComponent} from "./confirm/confirm-dialog.component";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {QRCodeModule} from "angularx-qrcode";
import {NoAutocompleteDirective} from './no-autocomplete.directive';
import {PageDebugComponent} from './page-debug/page-debug.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import * as moment from 'moment';
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeDeAt from '@angular/common/locales/de-AT';
import localeDeAtExtra from '@angular/common/locales/extra/de-AT';
import Bugsnag from '@bugsnag/js';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTabsModule} from "@angular/material/tabs";
import {SafePipe} from './safe.pipe';
import {MatTooltipModule} from "@angular/material/tooltip";
import {FileDownloadSnackbarComponent} from './file-download-snackbar/file-download-snackbar.component';
import {PageHelpComponent} from './page-help/page-help.component';
import {AngularFireAuth} from "@angular/fire/auth";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {AppDateTimePipe} from "./app-date-time.pipe";
import {NotifyLoadingComponent} from './notify-loading/notify-loading.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatStepperModule} from "@angular/material/stepper";
import {MatChipsModule} from "@angular/material/chips";
import {OverlayModule} from "@angular/cdk/overlay";
import {ChipColorDirective} from './chip-color.directive';
import {ContrastPipe} from './contrast.pipe';
import {AppColorPipe} from './app-color.pipe';
import {InfoLineComponent} from './info-line/info-line.component';
import {NgxPageScrollModule} from "ngx-page-scroll";

Bugsnag.start({apiKey: environment.bugsnag.apiKey, releaseStage: environment.bugsnag.releaseStage});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeDeAt, 'de-AT', localeDeAtExtra);

moment.locale('de');

@NgModule({
  declarations: [
    AppComponent,
    PageHomeComponent,
    FrameInComponent,
    CardComponent,
    PagePrivacyPolicyComponent,
    PageImprintComponent,
    PageTermsAndConditionsComponent,
    AppDatePipe,
    AppDateTimePipe,
    ConfirmDialogComponent,
    NoAutocompleteDirective,
    PageDebugComponent,
    SafePipe,
    FileDownloadSnackbarComponent,
    PageHelpComponent,
    NotifyLoadingComponent,
    ChipColorDirective,
    ContrastPipe,
    AppColorPipe,
    InfoLineComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatListModule,
    MatSnackBarModule,
    MatTabsModule,
    FlexLayoutModule,
    FormsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    MatTableModule,
    DragDropModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    ScrollingModule,
    QRCodeModule,
    MatStepperModule,
    MatPaginatorModule,
    MatChipsModule,
    OverlayModule,
    NgxPageScrollModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: "outline"
      }
    },
    {
      provide: REGION,
      useValue: 'europe-west1'
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {duration: 2500}
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-AT'
    },
    ScreenTrackingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(/*matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer*/injector: Injector, private auth: AngularFireAuth) {
    AppModule.injector = injector; // for quill image upload
    console.log(environment.stage);

    auth.user.subscribe(user => {
      if (!user || user.isAnonymous) {
        Bugsnag.setUser();
      } else {
        Bugsnag.setUser(user.uid, undefined, user.displayName ?? undefined);
      }
    });
  }
}
