<mat-sidenav-container autosize>
  <mat-sidenav [mode]="isMobile?'push':'side'" [(opened)]="navOpen" [disableClose]="!isMobile" [autoFocus]="false"
               role="navigation" [fixedInViewport]="true" class="main-sidenav">
    <div class="logo">
      <a routerLink="/"><img src="/assets/collla.png"></a>
      <div class="page-title">Wiki</div>
    </div>

    <!--<nav>
      <section>
        <h3>Inhalte</h3>
        <a routerLink="article" routerLinkActive="active">
          <mat-icon>article</mat-icon>
          <div mat-line>Beiträge</div>
        </a>
        <a routerLink="memo" routerLinkActive="active">
          <mat-icon>try</mat-icon>
          <div mat-line>Kurzmitteilungen</div>
        </a>
        <a routerLink="survey" routerLinkActive="active" *betaFeature>
          <mat-icon>poll</mat-icon>
          <div mat-line>Umfragen</div>
        </a>
        <a routerLink="area" routerLinkActive="active">
          <mat-icon>folder</mat-icon>
          <div mat-line>Bereiche</div>
        </a>
        <a routerLink="discount" routerLinkActive="active">
          <mat-icon>local_offer</mat-icon>
          <div mat-line>Vergünstigungen</div>
        </a>
        <a routerLink="form" routerLinkActive="active" *betaFeature>
          <mat-icon>drive_file_rename_outline</mat-icon>
          <div mat-line>Formulare</div>
        </a>
        <a routerLink="file" routerLinkActive="active">
          <mat-icon>attach_file</mat-icon>
          <div mat-line>Dateien</div>
        </a>
      </section>

      <section>
        <h3>Nachrichten</h3>
        <a routerLink="conversations" routerLinkActive="active">
          <mat-icon>textsms</mat-icon>
          <div>Nachrichten-Center</div>
        </a>

      </section>

      <section>
        <h3>Verwaltung</h3>
        <a routerLink="user" routerLinkActive="active">
          <mat-icon>people</mat-icon>
          <div mat-line>Benutzer</div>
        </a>
        <a routerLink="file-submission" routerLinkActive="active">
          <mat-icon>file_upload</mat-icon>
          <div mat-line>Dateien einreichen</div>
        </a>
        <a routerLink="settings" routerLinkActive="active">
          <mat-icon>settings</mat-icon>
          <div mat-line>Einstellungen</div>
        </a>
        <a routerLink="help" routerLinkActive="active">
          <mat-icon>help</mat-icon>
          <div mat-line>Hilfe</div>
        </a>
      </section>

      <section *onlyOps>
        <h3>Technik</h3>
        <a routerLink="backend-message" routerLinkActive="active">
          <mat-icon>forward_to_inbox</mat-icon>
          <div mat-line>Benachrichtigungen</div>
        </a>
      </section>
    </nav>-->
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar *ngIf="isMobile; else desktopToolbar" class="mobile-toolbar">
      <mat-toolbar-row>
        <button type="button" mat-icon-button (click)="navOpen=!navOpen">
          <mat-icon>menu</mat-icon>
        </button>
        <div class="mobile-toolbar-title">
          <div class="logo"><img src="/assets/collla.png"
                                 style="max-height: 32px; max-width: 32px; vertical-align: middle"></div>
          <div class="page-title">Wiki</div>
        </div>
        <div></div>
      </mat-toolbar-row>
    </mat-toolbar>
    <ng-template #desktopToolbar>
    </ng-template>

    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

