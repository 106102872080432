import {Component, OnDestroy, OnInit} from '@angular/core';


@Component({
  selector: 'app-page-debug',
  templateUrl: './page-debug.component.html',
  styleUrls: ['./page-debug.component.scss'],
  host: {class: 'content-page'}
})
export class PageDebugComponent implements OnInit, OnDestroy {

  constructor() {

  }

   ngOnInit() {

  }

  ngOnDestroy() {
  }



}
