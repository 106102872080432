import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FrameInComponent} from "./frame-in/frame-in.component";
import {PageHomeComponent} from "./page-home/page-home.component";
import {PageImprintComponent} from "./page-imprint/page-imprint.component";
import {PagePrivacyPolicyComponent} from "./page-privacy-policy/page-privacy-policy.component";
import {PageTermsAndConditionsComponent} from "./page-terms-and-conditions/page-terms-and-conditions.component";
import {PageDebugComponent} from "./page-debug/page-debug.component";
import {PageHelpComponent} from './page-help/page-help.component';

const routes: Routes = [
  {
    path: '',
    component: FrameInComponent,
    //pathMatch: 'full',
    canActivate: [
      //NotAdminAuthGuard
    ],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },

      {
        path: 'imprint',
        component: PageImprintComponent,
      },
      {
        path: 'privacy-policy',
        component: PagePrivacyPolicyComponent,
      },
      {
        path: 'terms-and-conditions',
        component: PageTermsAndConditionsComponent,
      },
      {
        path: 'debug',
        component: PageDebugComponent,
      },

      {
        path: 'home',
        component: PageHomeComponent,
      },

      {
        path: 'help',
        component: PageHelpComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "top"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
